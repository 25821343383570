import { render, staticRenderFns } from "./CourseZoomMeetingLeave.vue?vue&type=template&id=60b7a6a8&"
import script from "./CourseZoomMeetingLeave.vue?vue&type=script&lang=js&"
export * from "./CourseZoomMeetingLeave.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports